import axios from 'axios';

export default (token = false) => {
    
    return axios.create({
        baseURL: 'https://getco-fm-api.hidayahsmart.solutions/v1',
        headers: {
             'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    })
}