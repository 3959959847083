import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Statistic } from 'antd';
import { LikeOutlined, ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import {connect} from 'react-redux'
import { dashboardData } from '../../action/dashboard';
const Dashboard = ({ dashboardData }) => {
    const [data, setData] = useState(null)
    useEffect( () => {
        getData()
    }, [])
    const getData = async () => {
        const res = await dashboardData();
        setData(res)
    }
    return ( 
         data ? 
            <div  style={{height: '81vh', padding: '10px', margin: '5px 50px'}}>
           
                <Row gutter={16} >
                    <Col span={11}style={{backgroundColor: '#FFF', padding: '10px'}}>
                        <Statistic title="Total Client" value={data?.total_client} />
                    </Col>
                    <Col span={1}>&nbsp;</Col>
                    <Col span={12}style={{backgroundColor: '#FFF', padding: '10px'}}>
                        <Statistic title="Active Client" value={data?.active_client} />
                    </Col>
                </Row>
                <br />
                <br />
                <Row gutter={16}>
                    <Col span={11}style={{backgroundColor: '#FFF', padding: '10px'}}>
                        <Statistic title="Total Cable" value={data?.total_cable} />
                    </Col>
                    <Col span={1}>&nbsp;</Col>
                    <Col span={12}style={{backgroundColor: '#FFF', padding: '10px'}}>
                        <Statistic title="Total Junction" value={data?.total_junction} />
                    </Col>
                </Row>
                <br />
                <br />
                <Row gutter={16}>
                    <Col span={11}style={{backgroundColor: '#FFF', padding: '10px'}}>
                        <Statistic title="Total Pop" value={data?.total_pop} />
                    </Col>
                    <Col span={1}>&nbsp;</Col>
                    <Col span={12}style={{backgroundColor: '#FFF', padding: '10px'}}>
                        <Statistic title="Total Length (km)" value={data?.total_length > 0 ? (data?.total_length / 1000).toFixed(2) : '0'} />
                    </Col>
                </Row>
            
            </div>
        :null
     );
}
 
export default connect(null,{ dashboardData })(Dashboard);