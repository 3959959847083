import history from '../history';
import api from './apis';

import {
    ERROR_MESSAGE,
    LOADED,
    LOADING,
    SUCCESS_MESSAGE,
    VALIDATION_ERROR,
} from './type';

  export const createCabel = (data, cables) => async (dispatch) => {
    
    dispatch({type: LOADING});
    const newCab = [];
    const swap = (x, y) => {
      let temp = x;
      x = y;
      y = temp
      newCab.push([x,y])
    }
      for(let i = 0; i < cables.length; i++){
        swap(cables[i][0], cables[i][1])
      }
    
      const object = {
        cable_id : data?.cable_id,
        type : data?.type,
        description : data?.description,
        start_con_model: data?.start_con_model,
        start_con_id: data?.start_con_id,
        end_con_model: data?.end_con_model,
        end_con_id: data?.end_con_id,
        start_coordinate : newCab[0],
        end_coordinate : newCab[newCab.length - 1],
        core : Number(data?.core),
        length : data?.length,
        coordinates: [...newCab],
        coordinates_front: [...cables]
      };
      // console.log(object)
    
      try {
        const response = await api(localStorage.getItem("token")).post(
          "/cable/create",
          { ...object }
        );
    
        if (response.status === 201) {
          
        dispatch({type: LOADED});
        dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
        return response.status;
        }
      } catch (err) {
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
          dispatch({type: LOADED});
        /* ===========End============*/
      }
  };

  export const cabelDetail = (cable_id) => async (dispatch) => {
    
    dispatch({type: LOADING});
    
      try {
        const response = await api(localStorage.getItem("token")).get(
          `/cable/detail?cable_id=${cable_id}`
        );
    
        if (response.status === 200) {
          
        dispatch({type: LOADED});
        return response.data;
        }
      } catch (err) {
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
          dispatch({type: LOADED});
        /* ===========End============*/
      }
  };

  
  export const updateCabelDescription = (data, id) => async (dispatch) => {
    
    dispatch({type: LOADING});
   
    
      const object = {
        id : data?.cable_id,
        cable_id : id,
        type : data?.type,
        description : data?.description,
        note : data?.note,
        length : data?.length,
      };
      // console.log(object)
    
      try {
        const response = await api(localStorage.getItem("token")).patch(
          "/cable/update",
          { ...object }
        );
    
        if (response.status === 201) {
          
        dispatch({type: LOADED});
        dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
        return response.status;
        }
      } catch (err) {
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
          dispatch({type: LOADED});
        /* ===========End============*/
      }
  };
  
  export const updateCabelConnection = (data,id, point) => async (dispatch) => {
    
    dispatch({type: LOADING});
    const delObject = {
      "cable_id" : id,
      "point" : point,
      "con_model" : "",
      "con_id" : ""
    };
    const object = {
      "cable_id" : id,
      "point" : point,
      "con_model" : data?.con_model,
      "con_id" : data?.con_id
    };
    
      try {
        const res = await api(localStorage.getItem("token")).patch(
          "/cable/update/connection",
          { ...delObject }
        );
      
        if(res.status === 201){
          const response = await api(localStorage.getItem("token")).patch(
            "/cable/update/connection",
            { ...object }
          );
      
          if (response.status === 201) {
            
          dispatch({type: LOADED});
          dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
          return response.status;
          }
        }
        
      } catch (err) {
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
          dispatch({type: LOADED});
        /* ===========End============*/
      }
  };
  
  export const removeCableConnection = (id, point) => async (dispatch) => {
    
    dispatch({type: LOADING});
    const delObject = {
      "cable_id" : id,
      "point" : point,
      "con_model" : "",
      "con_id" : ""
    };
   
    
      try {
        const response = await api(localStorage.getItem("token")).patch(
          "/cable/update/connection",
          { ...delObject }
        );
      
      
          if (response.status === 201) {
            
          dispatch({type: LOADED});
          dispatch({ type: SUCCESS_MESSAGE, payload: "Cable connection removed successfully" });
          return response.status;
          }
        
      } catch (err) {
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
          dispatch({type: LOADED});
        /* ===========End============*/
      }
  };

  export const deleteCable = (id, password) => async (dispatch) => {
    
    dispatch({type: LOADING});
    const delObject = {
      "cable_id" : id,
      "password": password 
    };
   
    
      try {
        const response = await api(localStorage.getItem("token")).patch(
          "/cable/delete",
          { ...delObject }
        );
      
      
          if (response.status === 201) {
            
          dispatch({type: LOADED});
          dispatch({ type: SUCCESS_MESSAGE, payload: "Cable deleted successfully" });
          return response.status;
          }
        
      } catch (err) {
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
          dispatch({type: LOADED});
        /* ===========End============*/
      }
  };

  
  export const addDevice = (data, type) => async (dispatch) => {
    
    dispatch({type: LOADING});
   
    data.type = type;
  
    try {
      const response = await api(localStorage.getItem("token")).post(
        "/cable/intersect",
        { ...data }
      );
  
      if (response.status === 201) {
        
      dispatch({type: LOADED});
      dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
      return response.status;
      }
    } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
     
  };