import { Button, Col, Drawer, Form, Input,  Row, Select } from 'antd';
import React, { useEffect, useState } from 'react'

import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { CaretRightOutlined, VerticalAlignMiddleOutlined } from '@ant-design/icons'
import { createClient, getClients, getClientDetails} from '../../action/client';
import { connect } from 'react-redux';
import { ScrollAble, PopWrapper } from '../../styles/globalStyle';
const { Option } = Select;
const ClientList = ({getClients, position = [], mapData, createClient, getClientDetails, fnc1, fnc2, data1, refreshApi }) => {
    const [createDiv, setCreateDiv] = useState(false)
    const [details, setDetails] = useState(null);
    const [visible, setVisible] = useState(false);
    const [allClient, setAllClient] = useState(false);
    const [form] = Form.useForm();
    useEffect( () => {
      window.scrollTo(0, 0)
      getData('')
    }, [])
    
  const getData = async (search = '') => {
    const res = await getClients(search);
    if(res){
      setAllClient(res)
    }
  }
  const onFinish = async (values) => {

    const res = await createClient(values)
      if(res === 201){
        refreshApi()
        getData('')
        form.resetFields()
        setCreateDiv(false)
      }
    };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  // const options = [...
  //   data
  //     ? data?.map((item) =>
  //         createOption(
  //           item
  //         )
  //       )
  //     : null,
  // ];

  const handleSearch = (e) => {
    getData(e)
  }
  
    return ( 
        <>
      
          {allClient ?
          <>
          <Form.Item
            label=""
          >    
            <Input
              placeholder="Search Client"
              allowClear 
              onChange={e => handleSearch(e.target.value)}
            />
          </Form.Item>
          <ScrollAble>
            {allClient?.map((item,id) => (
                <PopWrapper active={item} onClick={ () => {fnc1(item); fnc2(item?.position); }}>
                  <Row>

                    <Col xs={5}>
                      <img src='./client.png'style={{width: '35px', marginBottom: '8px'}}/>
                    </Col>
                    <Col xs={14} style={{marginTop: '5px'}}>
                      <h6 style={{padding: '0px', fontWeight: 'bold',color: data1?.client_id === item?.client_id ? 'red' : null}}>
                        {item.name}
                      </h6>
                      <h6 style={{}}>{item.text}</h6>
                    </Col>
                    <Col xs={3}><CaretRightOutlined /></Col>
                  </Row>
                  
                  
                </PopWrapper>
            ))}
            </ScrollAble>
          </>
          :null}
        </>
     );
}
 
export default connect(null,{ createClient, getClients, getClientDetails })(ClientList);