import React, { useState } from 'react'
import { Button, Descriptions,Input, Select, PageHeader, Form, Row, Col, Tabs, Modal, Tag } from 'antd';
import { useEffect } from 'react';
import {connect} from 'react-redux';
import {updateCabelConnection,} from '../../action/cable';

import {getPops} from '../../action/pop'
import {getJunction} from '../../action/junction'
import {getClients} from '../../action/client'


const { Option } = Select;
const CableConnectionUpdate = ({ 
    visible, onCancel, data,
    updateCabelConnection, refresh,
    point,  getPops, getClients, getJunction,
    allRefresh, 
    }) => {
    
    const [model, setModel] = useState(null);
    const [allJunction, setAllJunction] = useState([]);
    const [allPops, setAllPops] = useState([]);
    const [allClient, setAllClient] = useState([]);
   
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            cable_id : data?.id,
          });
    }, [])
    

    const onFinish = async (values) => {
        const code = await updateCabelConnection(values,data?.id, point)
        if(code === 201){
            onCancel(false)
            allRefresh()
            refresh(data?.id)
            form.resetFields()
        }
      }
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

      
  const getData = async (search = '', name) => {
    if(name === 'pop'){
      const res = await getPops(search);
      if(res){
        setAllPops(res)
      }
    }
    if(name === 'client'){
      const res = await getClients(search);
      if(res){
        setAllClient(res)
      }
    }
    if(name === 'junction'){
      const res = await getJunction(search);
      if(res){
        setAllJunction(res)
      }
    }

  }

    return ( 
        <Modal title="Edit Cable Connection" visible={visible} onCancel={() =>{ onCancel(false)}}
            okButtonProps={{
                style: {
                display: "none",
                },
            }}
          >
            <Form
                  name="basic"
                  labelCol={{
                  span: 8,
                  }}
                  wrapperCol={{
                  span: 16,
                  }}
                  initialValues={{
                  remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  form={form}
              >

                      {/* <Form.Item
                          label="Select Point"
                          name="point"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Select  >
                            <Option value="start">Start</Option>
                            <Option value="end">End</Option>
                          </Select>
                      </Form.Item> */}
                      
                      <Form.Item
                          label="Select Model"
                          name="con_model"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                        <Select onChange={(e) => {
                            setModel(e);
                            form.setFieldsValue({
                                con_id : '',
                              });
                            }} >
                            <Option value="pop">POP</Option>
                            <Option value="client">Client</Option>
                            <Option value="junction">Junction</Option>
                        </Select>
                      </Form.Item>
                      
                      {model ? 
                      <Form.Item
                          label="Connection Point"
                          name="con_id"
                          rules={[{ required: true, message: 'Required' }]}
                          onChange={(e) => getData(e.target.value, model)}
                      >
                          <Select 
                          showSearch
                          // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                          optionFilterProp="children"
                          >
                            {model == 'pop' ?
                              allPops?.map((item,id) => (
                                <Option key={id} value={item.id}>{item.name} ({item?.pop_id})</Option>
                              ))
                            :
                            model == 'client' ?
                              allClient?.map((item, id) => (
                                <Option key={id} value={item.id}>{item.name} ({item?.client_id})</Option>
                              )) 
                            :
                            model == 'junction' ?
                              allJunction?.map((item, id) => (
                                <Option key={id} value={item.id}>{item.name} ({item?.junction_id})</Option>
                              ))
                            :
                              null
                            }
                          </Select>
                      </Form.Item>
                      :null}

                      <Form.Item
                          wrapperCol={{
                          offset: 8,
                          span: 16,
                          }}
                      >
                          <Button type="primary" htmlType="submit">
                          Submit
                          </Button>
                      </Form.Item>
              </Form>
        </Modal>
     );
}
 
export default connect(null,{updateCabelConnection,  getPops, getJunction, getClients})(CableConnectionUpdate);