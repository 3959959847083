import { Button, Col, Popconfirm, Row, Tabs } from 'antd';
import React, { useEffect, useState } from 'react'
import * as L from "leaflet";
import {
  Marker,
  Popup,
  useMap,
} from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { DeleteOutlined,EditOutlined, EyeFilled, RightOutlined } from '@ant-design/icons'
import { connect } from 'react-redux';
import JunctionDetails from './junctionDetails';
import { PopBox } from '../../styles/globalStyle';
import {getJunctionDetails, deleteJunction} from '../../action/junction'
const { TabPane } = Tabs;



const JunctionMarker = ({ data,isFocus, isFocusFnc, single, fnc1, fnc2, fnc3, refreshFnc, getJunctionDetails, deleteJunction, refreshApi }) => {
  const [id, setId] = useState(null);
  const [details, setDetails] = useState(null);
  const [visible, setVisible] = useState(false);
    const LeafIcon = L.Icon.extend({
      options: {
        iconSize:     [40, 40],
     }
    });
    
    const junctionicon = (data) => new LeafIcon({
      // iconUrl:  `https://s-api.bahonexpress.com/files/${data}`
      iconUrl:  `./Junction.png`,
      iconSize: [20, 20],
      iconAnchor: [12, 12],
      popupAnchor: [0, 0],
    })
    const junctioniconlg = (data) => new LeafIcon({
      // iconUrl:  `https://s-api.bahonexpress.com/files/${data}`
        iconUrl:  `https://cdn.iconscout.com/icon/free/png-256/location-marker-1719247-1460733.png`,
        iconSize: [40, 40],
        iconAnchor: [23, 35],
      popupAnchor: [0, 0],
    })

  
  

    const handleDelete = async(id, pass)  => {
      const res = await deleteJunction(id, pass);
      if(res === 201){
        refreshFnc(id)
        setVisible(false)
        setDetails(null)
      }
    }


  
  const RecenterAutomatically = ({lat,lng}) => {
    const map = useMap();
     useEffect(() => {
       map.setView([lat, lng]);
     }, [lat, lng]);
     return null;
   }
   
   const handleView = async(id) => {
    const res = await getJunctionDetails(id)
    if(res){
      isFocusFnc(false)
      setDetails(res)
      setId(id)
      setVisible(true)
    }
  }
  const refresh = async(id) => {
    const res = await getJunctionDetails(id)
    if(res){
      setDetails(res)
      setId(id)
    }
  }
  // console.log(data)
    return ( 
        <>
            {data?.map((item,i) => (
                <>
                  <Marker key={i} position={item?.position} icon={junctionicon()}
                  eventHandlers={{
                    click: () => {
                      fnc1(item); fnc2(item?.position);  fnc3("3");
                      handleView(item?.id)
                    },
                  
                    mouseover: (e) => { e.target.openPopup()},
                    mouseout: (e) => { e.target.closePopup()}
                  }}
                  >
                    <Popup minWidth="300" >
                      <Row style={{ minWidth: '250px'}}>
                        <Col xs={8}><h3>Junction Id</h3></Col> <Col xs={16}><h3>: <strong>{item?.junction_id}</strong></h3></Col>
                        <Col xs={8}><h3>Name</h3></Col> <Col xs={16}><h3>: <strong>{item?.name}</strong></h3></Col>
                        <Col xs={8}><h3>Address</h3></Col> <Col xs={16}><h3>: <strong>{item?.address}</strong></h3></Col>
                      </Row>
                    </Popup>
                    
                  </Marker>
                </>
            ))}
                         
                      <JunctionDetails
                        refreshApi={refreshApi}
                        details={details}
                        visible={visible}
                        closeFnc={() => setVisible(false)}
                        // closeFnc={() => {setDetails(null); setVisible(false); fnc1(false)}}
                        refresh={refresh}
                        deleteFnc={handleDelete}
                      /> 
                                  
            {single?.position && single?.junction_id 
            ?
              isFocus ?
                <>
                    <Marker  title={single?.name} position={single?.position}  icon={junctionicon()}
                    eventHandlers={{
                      click: () => {
                        fnc1(single); fnc3("1"); fnc2(single?.position);  
                        handleView(single?.id)
                      }
                    }}
                    > 
                    
                      {/* <RecenterAutomatically lat={single?.position[0]} lng={single?.position[1]} /> */}
                    </Marker>
                </>
              : 
                <>
                  <Marker  title={single?.name} position={single?.position}  icon={junctioniconlg()}
                  eventHandlers={{
                    click: () => {
                      fnc1(single); fnc3("1"); fnc2(single?.position);  
                      handleView(single?.id)
                    }
                  }}
                  > 
                  
                    <RecenterAutomatically lat={single?.position[0]} lng={single?.position[1]} />
                  </Marker>
                </>  
            : null}

        </>
     );
}
 
export default connect(null,{ getJunctionDetails, deleteJunction })(JunctionMarker);