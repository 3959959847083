import { Button, Col, Form, Modal, PageHeader, Row,  Tabs } from 'antd';
import React, {useRef, useEffect, useState } from 'react'
import {
  Marker,
  MapContainer,
  Polyline,
  TileLayer,
  FeatureGroup,
  useMap,
} from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { EditControl } from "react-leaflet-draw";
import {allPoint} from '../../action/map'
import { connect } from 'react-redux';
import * as L from "leaflet";
import { SideDataBar } from '../networkPoint/style';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Pop from './pops';
import ClientList from './clients'
import JunctionList from './junctions';
import { OpenStreetMapProvider } from "react-leaflet-geosearch";
import SearchControl from "../../components/searchControl";
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';

const { TabPane } = Tabs;

const Planing = ({allPoint}) => {
  const prov = OpenStreetMapProvider();

    const [center, setCenter] = useState([23.785638540195837, 90.42570563577543]);
    const [length, setLength] = useState(0)
    const [isModalVisible, setIsModalVisible] = useState(false);
    
   
    const [createPop, setCreatePop] = useState(false)
    const [createClient, setCreateClient] = useState(false)
    const [createJunction, setCreateJunction] = useState(false)
    const [single, setSingle] = useState(false);
    const [position, setPosition] = useState([]);
    const [form] = Form.useForm();
    const [allPops, setAllPops] = useState([]); 
    const [allClient, setAllClient] = useState([]); 
    const [allJunction, setAllJunction] = useState([]);
    const [allCable, setAllCable] = useState([]);
    const [showMarker, setShowMarker] = useState(true)
    // const [center, setCenter] = useState([23.785638540195837, 90.42570563577543]);
    const [tabNo, setTabNo] = useState("1")
    const [mData, setMData] = useState(null)
    const [satellite, setSatellite] = useState(1)
    const [cableCreating, setCableCreating] = useState(false)

    const LeafIcon = L.Icon.extend({
      options: {
        iconSize:     [40, 40],
     }
    });
    const popicon = (data) => new LeafIcon({
        // iconUrl:  `https://s-api.bahonexpress.com/files/${data}`
        iconUrl:  `./POP.png`,
        iconSize: [50, 39],
        iconAnchor: [18, 16],
        popupAnchor: [0, 0],
      })

    const clienticon = (data) => new LeafIcon({
      iconUrl:  `./client.png`,
      iconSize: [50, 39],
      iconAnchor: [25, 16],
      popupAnchor: [0, 0],
    })
    
    const junctionicon = (data) => new LeafIcon({
      // iconUrl:  `https://s-api.bahonexpress.com/files/${data}`
      iconUrl:  `./Junction.png`,
      iconSize: [20, 20],
      iconAnchor: [12, 12],
      popupAnchor: [0, 0],
    })
    const pointericon = (data) => new LeafIcon({
      iconUrl:  `https://cdn.iconscout.com/icon/free/png-256/location-marker-1719247-1460733.png`,
      // iconUrl:  `./Junction.png`,
      iconSize: [40, 40],
      iconAnchor: [15 , 35],
      popupAnchor: [10, 10],
    })
    useEffect( () => {
      window.scrollTo(0, 0)
      getData()
    }, [])

    
    const getData = async () => {
      const res = await allPoint()
      setAllPops(res?.pop[0]?.subData)
      setAllClient(res?.client[0]?.subData)
      setAllJunction(res?.junction[0]?.subData)
      setAllCable(res?.cable)
    }

    const mapRef = useRef();
    function toRad(Value) {
      return Value * Math.PI / 180;
  }
  
    function calDistance(lat1, lon1, lat2, lon2) {
      
      try{
  
          // console.log(lat1, lon1, lat2, lon2);
  
          var R = 6371; // km
          var dLat = toRad(lat2-lat1);
          var dLon = toRad(lon2-lon1);
          var lat1 = toRad(lat1);
          var lat2 = toRad(lat2);
  
          var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
          var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
          var d = R * c;

          return d
          
      }catch(error){
          console.log(error)
          return false
      }
      
  }
    const _onCreated = (e) => {
      let tot = 0;
      for(let i=1; i < e.layer._latlngs.length; i++ ){
        let a = calDistance(e.layer._latlngs[i - 1].lat,e.layer._latlngs[i - 1].lng, e.layer._latlngs[i].lat,e.layer._latlngs[i].lng);
        
        tot += a; 
      }
      setLength(Math.ceil(tot * 1000))
      if(tot * 1000){
        setIsModalVisible(true)
      }
    }

    const keyboardEvents = (event) =>{
      event.persist();
      if(event.key === 'z'){
        setCableCreating(true)
      }
    }
    const mapData = (data) => {
      setMData(data)
    } 
    const onChange = (key) => {
      setTabNo(key)
    };
    const RecenterAutomatically = ({lat,lng}) => {
      const map = useMap();
       useEffect(() => {
         map.setView([lat, lng]);
       }, [lat, lng]);
       return null;
     }
    return ( 
        <>
          <Row gutter={0}>
            <Col xs={24}>
                <MapContainer 
                    attributionControl={false}
                    center={center} 
                    zoom={13} 
                    ref={mapRef}
                    style={{ height: '88vh', width: '100%', padding: '0px' }}
                    maxZoom={25}
                    zoomControl={false}
                >
                  
                  {satellite === 1 ?
                      <ReactLeafletGoogleLayer 
                      apiKey='AIzaSyDslUl2FlUug8PaPRs2woRYjCi5EwqshVo' type={'satellite'} attribution={false} />
                    :
                      <TileLayer
                        url="https://fm.hidayahsmart.solutions/tile/{z}/{x}/{y}.png"
                      />
                    }
                    <SearchControl
                        provider={prov}
                        showMarker={true}
                        showPopup={false}
                        popupFormat={({ query, result }) => result.label}
                        maxMarkers={1}
                        retainZoomLevel={false}
                        animateZoom={true}
                        autoClose={true}
                        searchLabel={"Enter address or coordinate"}
                        keepResult={true}
                    />
                    
          <div style={{backgroundColor: '#FFF', zIndex: '1000', position: 'absolute', left: '210px', top: '2px'}} >
            <Button onClick={() => setSatellite(1)} disabled={satellite === 1 ? true : false} type='primary'>Satellite View</Button>
            <Button onClick={() => setSatellite(2)} disabled={satellite === 2 ? true : false}  type='primary'>OSM View</Button>
          </div>
                    {allPops?.map((item, i) =>  (
                      <Marker key={i} position={item?.position} icon={popicon()}
                      eventHandlers={{
                        click: () => {
                          setSingle(item); setCenter(item?.position);  setTabNo("1");
                        }
                      }}
                      ></Marker>
                    ))}
                    {allJunction?.map((item, i) =>  (
                      <Marker key={i} position={item?.position} icon={junctionicon()}
                      eventHandlers={{
                        click: () => {
                          setSingle(item); setCenter(item?.position);  setTabNo("3");
                        }
                      }}
                      ></Marker>
                    ))}
                    {allClient?.map((item, i) =>  (
                      <Marker key={i} position={item?.position} icon={clienticon()}
                      eventHandlers={{
                        click: () => {
                          setSingle(item); setCenter(item?.position);  setTabNo("2");
                        }
                      }}
                      ></Marker>
                    ))}
                    {/* {single ? 
                <RecenterAutomatically lat={single?.position[0]} lng={single?.position[1]} />
              :null} */}
                    {single?.position
            ? 
                <Marker  title={single?.name} position={single?.position}  icon={pointericon()}
                eventHandlers={{
                  click: () => {
                    setSingle(single); setCenter(single?.position);  
                  }
                }}
                > 
                
                <RecenterAutomatically lat={single?.position[0]} lng={single?.position[1]} />
                </Marker> 
            : null}
                    <FeatureGroup>
                                  
                      {allCable ?
                          allCable?.map(item => (
                            item?.core_available === true ? 
                            <Polyline
                              dashArray= {
                                item?.type === 'underground' ? '2, 5' :
                                item?.type === 'wireless' ? '5, 10' 
                                : null}
                              pathOptions={{ 
                                color: item?.core === 2 ? '#ADD8E6' :
                                item?.core === 4 ? '#e87472' :
                                item?.core === 8 ? 'grey' :
                                item?.core === 12 ? '#CBC3E3' :
                                item?.core === 24 ? '#FED8B1' :
                                item?.core === 36 ? '#FFFFE0' :
                                item?.core === 48 ? '#90EE90' :
                              'black',
                                
                                  weight: '5',
                              }}
                              lineCap="Ok"
                              positions={item?.path}
                            >
                            </Polyline>
                            :
                            
                            <Polyline
                              dashArray= {
                                item?.type === 'underground' ? '2, 5' :
                                item?.type === 'wireless' ? '5, 10' 
                                : null}
                              pathOptions={{ 
                                color: item?.core === 2 ? 'blue' :
                                        item?.core === 4 ? 'red' :
                                        item?.core === 8 ? 'black' :
                                        item?.core === 12 ? 'purple' :
                                        item?.core === 24 ? 'orange' :
                                        item?.core === 36 ? 'yellow' :
                                        item?.core === 48 ? 'green' :
                                      'black',
                                  weight: '5',
                              }}
                              lineCap="Ok"
                              positions={item?.path}
                            >
                            </Polyline>
                          ))
                      :null}
                      <EditControl
                        position="topright"
                        onCreated={_onCreated}
                        edit={{
                        remove: false,
                        edit: false
                        }}
                        draw={{
                          rectangle: false,
                          circle: false,
                          circlemarker: false,
                          polygon: false,
                          marker: false,
                        }}
                      />
                            
                    </FeatureGroup>
                </MapContainer>
                <Modal title="Cable Length" visible={isModalVisible} onOk={() => setIsModalVisible(false)} onCancel={() => setIsModalVisible(false)}>
                  <h2 st>Cable Length is <strong>{length}</strong> meter</h2>
                </Modal>
            </Col>
          </Row>
          
          <SideDataBar title="Pop List" placement="right" onKeyPress={keyboardEvents}>
            <PageHeader
              className="site-page-header"
              // onBack={() => window.history.back()}
              backIcon={<ArrowLeftOutlined style={{color: '#FFF'}} />}
              subTitle={<p style={{color: '#FFF', margin: '0px'}}>Models</p>}
              style={{ color: '#FFF', backgroundColor: '#1565A6', padding: '5px 10px'}}
            />
            <Tabs defaultActiveKey="1" onChange={onChange} activeKey={tabNo} >
              <TabPane tab={<span style={{fontSize: '11px', padding: '-2px'}}>Pop</span>} key="1">
                <Pop isFocusFnc={setCableCreating} mapData={mapData} mData={mData} position={position} form={form} refreshApi={getData} createFnc={createPop} setCreatefnc={setCreatePop} drawerFnc fnc1 = {setSingle} fnc2 = {setCenter} data1 = {single} />
                </TabPane>
              <TabPane tab={<span style={{fontSize: '11px', padding: '-2px'}}>Client</span>} key="2">
                <ClientList mapData={mapData} mData={mData} position={position} allClient={allClient} form={form} refreshApi={getData} createFnc={createClient} setCreatefnc={setCreateClient} fnc1 = {setSingle} fnc2 = {setCenter} data1 = {single} />
              </TabPane>
              <TabPane tab={<span style={{fontSize: '11px', padding: '-2px'}}>Junction</span>} key="3">
                <JunctionList position={position} allJunction={allJunction}  form={form} refreshApi={getData} createFnc={createJunction} setCreatefnc={setCreateJunction} fnc1 = {setSingle} fnc2 = {setCenter} data1 = {single} />
              </TabPane>
            </Tabs>
          </SideDataBar>

        </>
     );
}
 
export default connect(null,{ allPoint })(Planing);