import { Col, Form, Row, PageHeader, Tabs, Button, Select } from 'antd';
import React, {useRef, useEffect, useState } from 'react'
import {Black, Blue,  CoreBox, Green, Grey, MContainer, Orange, Purple, Red, SideDataBar, Yellow} from './style'
import {
  Marker,
  MapContainer,
  Popup,
  TileLayer,
  useMapEvents,
  useMap,
} from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { ArrowLeftOutlined } from '@ant-design/icons'
import {allPoint} from '../../action/map'
import {getArea} from '../../action/other'
import { connect } from 'react-redux';
import ClientList from '../clients'
import ClientMarker from '../clients/marker'
import Pop from '../pop';
import PopMarker from '../pop/marker';
import JunctionMarker from '../junction/marker';
import JunctionList from '../junction';
import Cable from '../cable';
import FocusedMap from './focusedMap';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { OpenStreetMapProvider } from "react-leaflet-geosearch";
import SearchControl from "../../components/searchControl";
import './style.css'
import * as L from "leaflet";
const { Option } = Select;
const { TabPane } = Tabs;

const NetworkPoint = ({ allPoint, getArea }) => {
  const prov = new OpenStreetMapProvider({
    params: {
      'accept-language': 'en', // render results in English
      countrycodes: 'ca,us', // limit search results to Canada & United States
    }
  });
  const LeafIcon = L.Icon.extend({
    options: {
      iconSize:     [40, 40],
   }
  });
  const icon = (data) => new LeafIcon({
    iconUrl:  `https://cdn.iconscout.com/icon/free/png-256/location-marker-1719247-1460733.png`,
    iconSize: [20, 20],
    iconAnchor: [12, 12],
    popupAnchor: [0, 0],
  })
    const [createPop, setCreatePop] = useState(false)
    const [createClient, setCreateClient] = useState(false)
    const [createJunction, setCreateJunction] = useState(false)
    const [single, setSingle] = useState(false);
    const [position, setPosition] = useState([]);
    const [form] = Form.useForm();
    const [allPops, setAllPops] = useState([]); 
    const [allClient, setAllClient] = useState([]); 
    const [allJunction, setAllJunction] = useState([]);
    const [allCable, setAllCable] = useState([]);
    const [showMarker, setShowMarker] = useState(true)
    const [center, setCenter] = useState([23.785638540195837, 90.42570563577543]);
    const [tabNo, setTabNo] = useState("1")
    const [mData, setMData] = useState(null)
    const [cableCreating, setCableCreating] = useState(false)
    const [setCord, setSetCord] = useState(1)
    const [satellite, setSatellite] = useState(1)
    const [breakingPoint, setBreakingPoint] = useState(null)
    const [searchedArea, setSearchedArea] = useState(null)
    const [searchedCordinate, setSearchedCordinate] = useState(null)
    const [popConnection, setPopConnection] = useState(null);
    useEffect( () => {
      window.scrollTo(0, 0)
      getData()
    }, [])

    const LocationFinderDummy = () => {
        const map = useMapEvents({
            click(e) {
              setPosition([e.latlng.lat, e.latlng.lng])
            },
        });
        return null;
    };


    const handlePositionClick = (value) => {
      
      form.setFieldsValue({
        lat : value[0],
        long : value[1],
      });
      
    }

    const mapRef = useRef();

    const getData = async () => {
      const res = await allPoint()
      setAllPops(res?.pop[0]?.subData)
      setAllClient(res?.client[0]?.subData)
      setAllJunction(res?.junction[0]?.subData)
      setAllCable(res?.cable)
    }
    const refresh = async () => {
      const res = await allPoint()
      setAllPops(res?.pop[0]?.subData)
      setAllClient(res?.client[0]?.subData)
      setAllJunction(res?.junction[0]?.subData)
      setAllCable(res?.cable)
      setSingle(false)
    }

    const onChange = (key) => {
      setTabNo(key)
    };
    
  const mapData = (data) => {
    setMData(data)
  } 
  const keyboardEvents = (event) =>{ 
    event.persist();
    if(event.key === 'Z'){
      setCableCreating(true)
    }
    if(event.key === 'A'){
      getData();
      setPopConnection(null)
    }
    if(event.key === 'R'){
      setSingle(false)
      setMData(null)
    }
  }
  const handleSearchLocation = async (e) => {
    const res = await getArea(e);
    // console.log(res)
    setSearchedArea(res)
  }
  const handleSearchResult = async (e) => {
    // const res = await getArea(e);\
    const cor = e.split('+')[1]
    const validCor = cor.split('/')
    setSearchedCordinate([validCor[0].split('_')[0], validCor[0].split('_')[1]])
  }

  const RecenterAutomatically = ({lat,lng}) => {
    const map = useMap();
     useEffect(() => {
       map.setView([lat, lng]);
     }, [lat, lng]);
     return null;
   }


  
    return ( 
        <>
          <CoreBox>
            <Row>
              <Col xs={8}><Blue></Blue>&nbsp; 2 Core</Col>
              <Col xs={8}><Red></Red>&nbsp; 4 Core</Col>
              <Col xs={8}><Black></Black>&nbsp; 8 Core</Col>
              <Col xs={8}><Purple></Purple>&nbsp; 12 Core</Col>
              <Col xs={8}><Orange></Orange>&nbsp; 24 Core</Col>
              <Col xs={8}><Yellow></Yellow>&nbsp; 36 Core</Col>
              <Col xs={8}><Green></Green>&nbsp; 48 Core</Col>
            </Row>
          </CoreBox>
          <div style={{backgroundColor: '#FFF', zIndex: '1000', position: 'absolute', left: '450px', top: '61px'}} >
            <Button onClick={() => setSetCord(1)} disabled={setCord === 1 ? true : false} type='primary'>On Screen</Button>
            <Button onClick={() => setSetCord(2)} disabled={setCord === 2 ? true : false}  type='primary'>Search</Button>
          </div>
          <div style={{backgroundColor: '#FFF', zIndex: '1000', position: 'absolute', left: '210px', top: '61px'}} >
            <Button onClick={() => setSatellite(1)} disabled={satellite === 1 ? true : false} type='primary'>Satellite View</Button>
            <Button onClick={() => setSatellite(2)} disabled={satellite === 2 ? true : false}  type='primary'>OSM View</Button>
          </div>

          <Row gutter={0} onKeyPress={keyboardEvents}>
              <Col xs={24}>
                  <MapContainer 
                    attributionControl={true}
                    center={center} 
                    zoom={15} 
                    ref={mapRef}
                    style={{ height: '88vh', width: '100%', padding: '0px' }}
                    maxZoom={25}
                    zoomControl={false}
                  >
                    {satellite === 1 ?
                      <ReactLeafletGoogleLayer 
                      apiKey='AIzaSyDslUl2FlUug8PaPRs2woRYjCi5EwqshVo' type={'satellite'} />
                    :
                      <TileLayer
                        url="https://fm.hidayahsmart.solutions/tile/{z}/{x}/{y}.png"
                      />
                    }
                  
                      {setCord === 2 ? 
                      <>
                      <SearchControl
                        provider={prov}
                        showMarker={true}
                        showPopup={false}
                        popupFormat={({ query, result }) => result.label}
                        maxMarkers={1}
                        retainZoomLevel={false}
                        animateZoom={true}
                        autoClose={true}
                        searchLabel={"Enter address or coordinate"}
                        keepResult={true}
                        fnc = {setPosition}
                        
                      />
                      {/* <Form.Item
                          label="Core"
                          name="core"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Select 
                            style={{zIndex: '100000', width: '600px', position: 'absolute', left: '570px', top: '1px'}}
                            showSearch
                            allowClear
                            // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            // optionFilterProp="children"
                            onSearch={(e) => handleSearchLocation(e)}
                            // onChange=
                            onSelect = {(e) => handleSearchResult(e)}
                          >
                            {
                              searchedArea?.map((item, id) => (
                                <Option key={id} value={item.display_name + '+' + item?.lat+ '_' + item?.lon + '/' + item?.boundingbox }>{item.name} ({item?.display_name})</Option>
                              ))
                            }
                          </Select>
                      </Form.Item> */}
                      </>
                      :
                      
                      <>
                      <LocationFinderDummy />
                      {position.length > 0 ? 
                        showMarker ?
                          
                        <Marker position={position} icon={icon()} >
                                                    
                        </Marker>
                          :null
                      : null}
                      </>}
                      
                   
                    {allPops ? 
                    <Cable bPointFnc={setBreakingPoint} bPoint = {breakingPoint} isFocus = {setCableCreating} fnc2={setCenter} mData={mData} allPops={allPops} allClient={allClient} allJunction={allJunction}  data={allCable} refreshFnc={getData} markerFnc={setShowMarker}  />
                    :null}
                      {/* {handlePositionClick(position)} */}
                    
                              {/* {console.log(allPops)} */}
                      <PopMarker refreshApi={getData} isFocusFnc={setCableCreating} isFocus={cableCreating}  mapData={mapData} refreshFnc={refresh}  mData={mData} data = { allPops } single={single} fnc1 = {setSingle} fnc2 = {setCenter} fnc3 ={setTabNo}/>
                      <ClientMarker refreshApi={getData} isFocusFnc={setCableCreating} isFocus={cableCreating} mapData={mapData} mData={mData} refreshFnc={refresh} data = { allClient } single={single} fnc1 = {setSingle} fnc2 = {setCenter} fnc3 ={setTabNo} />
                      <JunctionMarker refreshApi={getData} isFocusFnc={setCableCreating} isFocus={cableCreating} refreshFnc={refresh} data= { allJunction}  single={single}  fnc1 = {setSingle} fnc2 = {setCenter} fnc3 ={setTabNo}/> 

                      {searchedCordinate ? 
                        <RecenterAutomatically lat={searchedCordinate[0]} lng={searchedCordinate[1]} />
                      :null}
                  </MapContainer>
                  
              </Col>
          </Row>

          <SideDataBar placement="right" onKeyPress={keyboardEvents}>
            <PageHeader
              className="site-page-header"
              // onBack={() => window.history.back()}
              backIcon={<ArrowLeftOutlined style={{color: '#FFF'}} />}
              subTitle={<p style={{color: '#FFF', margin: '0px'}}>Models</p>}
              style={{ color: '#FFF', backgroundColor: '#1565A6', padding: '5px 10px'}}
            />
            <Tabs defaultActiveKey="1" onChange={onChange} activeKey={tabNo} >
              <TabPane tab={<span style={{fontSize: '11px', padding: '-2px'}}>Pop</span>} key="1">
                <Pop 
                      setPopConnection= {setPopConnection}
                      popConnection={popConnection}
                      allData = {getData}
                      popList={setAllPops} 
                      cableList={setAllCable}
                      clientList={setAllClient}
                      junctionList={setAllJunction}
                      isFocusFnc={setCableCreating} mapData={mapData} mData={mData} position={position} allClient={allClient} allJunction={allJunction} form={form} refreshApi={getData} createFnc={createPop} setCreatefnc={setCreatePop} drawerFnc fnc1 = {setSingle} fnc2 = {setCenter} data1 = {single} />
                </TabPane>
              <TabPane tab={<span style={{fontSize: '11px', padding: '-2px'}}>Client</span>} key="2">
                <ClientList
                  setPopConnection= {setPopConnection}
                  popConnection={popConnection}
                  mapData={mapData} mData={mData} position={position} allClient={allClient} form={form} refreshApi={getData} createFnc={createClient} setCreatefnc={setCreateClient} fnc1 = {setSingle} fnc2 = {setCenter} data1 = {single} />
              </TabPane>
              <TabPane tab={<span style={{fontSize: '11px', padding: '-2px'}}>Junction</span>} key="3">
                <JunctionList
                setPopConnection= {setPopConnection}
                popConnection={popConnection}
                position={position} allJunction={allJunction}  form={form} refreshApi={getData} createFnc={createJunction} setCreatefnc={setCreateJunction} fnc1 = {setSingle} fnc2 = {setCenter} data1 = {single} />
              </TabPane>
            </Tabs>
          </SideDataBar>
        </>
     );
}
 
export default connect(null,{ allPoint, getArea })(NetworkPoint);