import { Button, Checkbox, Form, Input } from 'antd';
import React, { useState } from 'react';

const DeleteConfirmation = ({id, fnc, open, closeFnc}) => {
  
  const [form] = Form.useForm();
    const onFinish = (values) => {
          fnc(id, values.password);
          form.resetFields()
          closeFnc();
      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
    return (  
    <>
      <Form
        name="Delete"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        requiredMark={false}
        form={form}
        style={{ width: '300px', float: 'right'}}
      >
      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="danger" htmlType="submit">
          Delete
        </Button>
        &nbsp;
        <Button type="primary" htmlType="submit" onClick={() => closeFnc()}>
          Cancel
        </Button>
      </Form.Item>
    </Form>
      </> );
}
 
export default DeleteConfirmation;