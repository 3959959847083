import { Button, Col, Popconfirm, Row, Tabs } from 'antd';
import React, { useEffect, useState } from 'react'
import * as L from "leaflet";
import {
  Marker,
  Popup,
  Tooltip,
  useMap,
} from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { DeleteOutlined, EyeFilled } from '@ant-design/icons'
import { connect } from 'react-redux';
import { PopBox } from '../../styles/globalStyle';
import { getPopDetails, deletePop } from '../../action/pop';
import PopDetails from './popDetails';
const { TabPane } = Tabs;



const PopMarker = ({ data, single, refreshFnc, 
  fnc1, fnc2, fnc3, getPopDetails, deletePop ,
   mapData, isFocus, isFocusFnc, refreshApi
   }) => {
  const [details, setDetails] = useState(null);
  const [visible, setVisible] = useState(false);
    const LeafIcon = L.Icon.extend({
      options: {
        iconSize:     [40, 40],
     }
    });
    const popicon = (data) => new LeafIcon({
        // iconUrl:  `https://s-api.bahonexpress.com/files/${data}`
        iconUrl:  `./POP.png`,
        iconSize: [50, 39],
        iconAnchor: [18, 16],
        popupAnchor: [0, 0],
        html: '<span class="my-div-span">RAF Banff Airfield</span>'
        
      })

      const popiconlg = (data) => new LeafIcon({
        // iconUrl:  `https://s-api.bahonexpress.com/files/${data}`
        iconUrl:  `https://cdn.iconscout.com/icon/free/png-256/location-marker-1719247-1460733.png`,
        iconSize: [40, 40],
        iconAnchor: [10, 25],
        // iconAnchor: [12, 12],
        popupAnchor: [0, 0],
      })

  
  
      const refresh = async(id) => {
        const res = await getPopDetails(id)
        if(res){
          setDetails(res)
        }
      } 

      const handleDelete = async(id, pass)  => {
        const res = await deletePop(id, pass);
        if(res === 201){
          refreshFnc(id);
          setVisible(false);
          setDetails(null);
        }
      }


  
  const RecenterAutomatically = ({lat,lng}) => {
    const map = useMap();
     useEffect(() => {
       map.setView([lat, lng]);
     }, [lat, lng]);
     return null;
   }
  // console.log(data)
  
  const handleView = async(id) => {
    const res = await getPopDetails(id)
    if(res){
      isFocusFnc(false)
      setDetails(res)
      setVisible(true)
    }
  }
  // {console.log(data)}
    return ( 
        <>
            {data?.map((item,i) => (
                <>
                  <Marker key={i} position={item?.position} icon={popicon()}
                   eventHandlers={{
                    click: () => {
                      fnc1(item); fnc2(item?.position);  fnc3("1");
                      handleView(item?.id)
                    },
                    mouseover: (e) => { e.target.openPopup()},
                    mouseout: (e) => { e.target.closePopup()}
                  }}>
                      {/* <Tooltip permanent>
                        <span>{item?.name}</span>
                      </Tooltip> */}
                    <Popup minWidth="300" >
                      <Row style={{ minWidth: '250px'}}>
                        <Col xs={8}><h3>Pop Id</h3></Col> <Col xs={16}><h3>: <strong>{item?.pop_id}</strong></h3></Col>
                        <Col xs={8}><h3>Name</h3></Col> <Col xs={16}><h3>: <strong>{item?.name}</strong></h3></Col>
                        <Col xs={8}><h3>Address</h3></Col> <Col xs={16}><h4>: <strong>{item?.address}</strong></h4></Col>
                      </Row>
                    </Popup>

                      
                  </Marker>
                </>
            ))} 
            {single?.position && single?.pop_id 
            ? 
              isFocus ?
                <>
                    <Marker  title={single?.name} position={single?.position}  icon={popicon()}
                    eventHandlers={{
                      click: () => {
                        fnc1(single); fnc3("1"); fnc2(single?.position);  
                        handleView(single?.id)
                      }
                    }}
                    > 
                    
                    {/* <RecenterAutomatically lat={single?.position[0]} lng={single?.position[1]} /> */}
                    </Marker>
                </>
              : 
                <>
                <Marker  title={single?.name} position={single?.position}  icon={popicon()}
                eventHandlers={{
                  click: () => {
                    // fnc1(single); fnc3("1"); fnc2(single?.position);  
                    handleView(single?.id)
                  }
                }}
                > 
                
                <RecenterAutomatically lat={single?.position[0]} lng={single?.position[1]} />
                </Marker>
                </>    
            : null}

              <PopDetails
                        details={details}
                        visible={visible}
                        refreshApi={refreshApi}
                        closeFnc={() => { setDetails(null); setVisible(false)}}
                        // closeFnc={() => { setDetails(null); setVisible(false); fnc1(false)}}
                        refresh={refresh}
                        deleteFnc = {handleDelete}
                        mapData={mapData}
                      />
          </>
     );
}
 
export default connect(null,{ getPopDetails, deletePop })(PopMarker);