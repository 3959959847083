import { Button, Col, Drawer, Form, Input,  Popconfirm,  Row, Select } from 'antd';
import React, {useEffect, useState } from 'react'
import * as L from "leaflet";
import DEFAULT from './default.png'
import {
  FeatureGroup,
  Popup,
  Polyline,
} from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { EditControl } from "react-leaflet-draw";
import { DeleteOutlined,EditOutlined, EyeFilled, RightOutlined } from '@ant-design/icons'
import {cabelDetail, deleteCable, createCabel} from '../../action/cable';
import { connect } from 'react-redux';
import history from '../../history';

import CableDetails from './cableDetails';
import { PopBox } from '../../styles/globalStyle';
import {getPops} from '../../action/pop'
import {getClients} from '../../action/client'
import {getJunction} from '../../action/junction'

const { Option } = Select;

const Cable = ({ createCabel, data, refreshFnc,
   markerFnc, cabelDetail, deleteCable,
   mData, getPops, getClients, getJunction,
   fnc2, isFocus, bPoint, bPointFnc
  
  }) => {
    const [createDiv, setCreateDiv] = useState(false)
    const LeafIcon = L.Icon.extend({
      options: {
        iconSize:     [40, 40],
     }
    });
  
  
    const [allJunction, setAllJunction] = useState([]);
    const [allPops, setAllPops] = useState([]);
    const [allClient, setAllClient] = useState([]);
    const [newLayer, setNewLayer] = useState([]);
    const [form] = Form.useForm();
    const [mapLayers, setMapLayers] = useState([]);
    const [LeafletId, setLeafletId] = useState(null);
    const [smodel, setSModel] = useState(null);
    const [emodel, setEModel] = useState(null);
    const [core, setCore] = useState(null);
    const [details, setDetails] = useState(null);
    const [visible, setVisible] = useState(false);
    const defaultIcon = new LeafIcon({
      iconUrl: DEFAULT
    });

    useEffect( () => {
      window.scrollTo(0, 0)
      
    }, [])
  const onFinish = async (values) => {
    const res = await createCabel(values, newLayer)
    if(res === 201){
      form.resetFields()
      refreshFnc()
      markerFnc(true)
      setCreateDiv(false)
    }
  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const editPath = async (id) => {
    history.push(`editPath?id=${id}`)
  }

  const handleDelete = async(id, pass)  => {
    const res = await deleteCable(id, pass)
   if(res  === 201 ){
    refreshFnc()
    
    setVisible(false)
   }
  }

  const _onCreated = (e) => {
    let d = [];
    for(let i=0; i < e.layer._latlngs.length; i++ ){
      
      d.push([e.layer._latlngs[i].lat,e.layer._latlngs[i].lng])
    }
    setNewLayer(d)
    const { layerType, layer } = e;
    setCreateDiv(true)

    if (layerType === "polyline") {
      const { _leaflet_id } = layer;
      setLeafletId(_leaflet_id);
      setMapLayers((layers) => [
        ...layers,
        {
          id: _leaflet_id,
          latlngs: layer._latlngs,
          color: layer.options.color,
          color_opacity: layer.options.opacity,
          color_width: layer.options.weight,
          smoothFactor: layer.options.smoothFactor,
        },
      ]);
    }
  }
  const _onDrawVertex = (e) => {
    // console.log(e.layers._layers[Object.keys(e.layers._layers)[Object.keys(e.layers._layers).length -1]]?._latlng)
    fnc2([e.layers._layers[Object.keys(e.layers._layers)[Object.keys(e.layers._layers).length -1]]?._latlng?.lat, e.layers._layers[Object.keys(e.layers._layers)[Object.keys(e.layers._layers).length -1]]?._latlng?.lng])
  }
  const _onEdited = (e) => {
    try {
      const {
        layers: { _layers },
      } = e;

      const hello = [];
      // console.log(e.layers._layers[LeafletId]._latlngs);

      for (let i = 0; i < e.layers._layers[LeafletId]._latlngs.length; i++) {
        hello.push([
          e.layers._layers[LeafletId]._latlngs[i].lat,
          e.layers._layers[LeafletId]._latlngs[i].lng,
        ]);
      }
    } catch (error) {
      console.log(error);
    }
    console.log(e);

  };

  const _onDeleted = (e) => {
    console.log(e);
    const {
      layers: { _layers },
    } = e;
    Object.values(_layers).map(({ _leaflet_id }) => {
      setMapLayers((layers) => layers.filter((l) => l.id !== _leaflet_id));
    });
  };

  const handleView = async (id) => {
    const res = await cabelDetail(id)
    if(res){
      setCore(res?.cores)
      setDetails(res?.detail)
      setVisible(true)
    }
  }

  const refresh = async (id) => {
    const res = await cabelDetail(id)
    if(res){
      setCore(res?.cores)
      setDetails(res?.detail)
      setVisible(true)
    }
  }


  const getData = async (search = '', name) => {
    if(name === 'pop'){
      const res = await getPops(search);
      if(res){
        setAllPops(res)
      }
    }
    if(name === 'client'){
      const res = await getClients(search);
      if(res){
        // console.log(res)
        setAllClient(res)
      }
    }
    if(name === 'junction'){
      const res = await getJunction(search);
      if(res){
        setAllJunction(res)
      }
    }

    

  }
    return ( 
        <>
          <Drawer title="Create Line" width={600} placement="right" onClose={() => setCreateDiv(false)} visible={createDiv}>
              <Form
                  name="basic"
                  labelCol={{
                  span: 8,
                  }}
                  wrapperCol={{
                  span: 16,
                  }}
                  initialValues={{
                  remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  form={form}
              >
                      <Form.Item
                          label="Cable ID"
                          name="cable_id"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Input />
                      </Form.Item>

                      <Form.Item
                          label="Type"
                          name="type"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Select  >
                            <Option value="line">Line</Option>
                            <Option value="underground">Underground</Option>
                            <Option value="wireless">Wireless</Option>
                          </Select>
                      </Form.Item>
                      <Form.Item
                          label="Start From"
                          name="start_con_model"
                      >
                          <Select 
                          onChange={(e) => {
                            setSModel(e)
                            form.setFieldsValue({
                              start_con_id : '',
                            });}} >
                            <Option value="pop">POP</Option>
                            <Option value="client">Client</Option>
                            <Option value="junction">Junction</Option>
                            <Option value="">None</Option>
                          </Select>
                      </Form.Item>
                      {smodel ? 
                      <Form.Item
                          label="Starting Point"
                          name="start_con_id"
                          rules={[{ required: true, message: 'Required' }]}
                          onChange={(e) => getData(e.target.value, smodel)}
                      >
                          <Select
                          showSearch
                          // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                          optionFilterProp="children"
                          >
                            {smodel == 'pop' ?
                              allPops?.map((item, id) => (
                                <Option key={id} value={item.id}>{item.name} ({item?.pop_id})</Option>
                              ))
                            :
                            smodel == 'client' ?
                              allClient?.map((item, id) => (
                                // console.log(item.name)
                                <Option key={id} value={item.id}> {item.name} ({item?.client_id})</Option>
                              )) 
                            :
                            smodel == 'junction' ?
                              allJunction?.map((item, id) => (
                                <Option key={id} value={item.id}>{item.name} ({item?.junction_id})</Option>
                              ))
                            :
                              null
                            }
                          </Select>
                      </Form.Item>
                      :null}
                      <Form.Item
                          label="End to"
                          name="end_con_model"
                      >
                          <Select onChange={(e) =>{
                            setEModel(e);
                            form.setFieldsValue({
                              end_con_id : '',
                            });}} >
                            <Option value="pop">POP</Option>
                            <Option value="client">Client</Option>
                            <Option value="junction">Junction</Option>
                            <Option value="">None</Option>
                          </Select>
                      </Form.Item>
                      {emodel ? 
                      <Form.Item
                          label="Ending Point"
                          name="end_con_id"
                          rules={[{ required: true, message: 'Required' }]}
                          onChange={(e) => getData(e.target.value, emodel)}
                      >
                          <Select
                          showSearch
                          // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                          optionFilterProp="children"
                          >
                            {emodel == 'pop' ?
                              allPops?.map((item, id) => (
                                <Option key={id} value={item.id}>{item.name} ({item?.pop_id})</Option>
                              ))
                            :
                            emodel == 'client' ?
                              allClient?.map((item, id) => (
                                <Option key={id} value={item.id}>{item.name} ({item?.client_id})</Option>
                              )) 
                            :
                            emodel == 'junction' ?
                              allJunction?.map((item, id) => (
                                <Option key={id} value={item.id}>{item.name} ({item?.junction_id})</Option>
                              ))
                            :
                              null
                            }
                          </Select>
                      </Form.Item>
                      :null}
                      <Form.Item
                          label="Core"
                          name="core"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Select 
                          showSearch
                          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                          optionFilterProp="children"
                          >
                            <Option value="2">2</Option>
                            <Option value="4">4</Option>
                            <Option value="8">8</Option>
                            <Option value="12">12</Option>
                            <Option value="24">24</Option>
                            <Option value="36">36</Option>
                            <Option value="48">48</Option>
                          </Select>
                      </Form.Item>
                      <Form.Item
                          label="Length (meter)"
                          name="length"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Input />
                      </Form.Item>

                      <Form.Item
                          label="Note"
                          name="note"
                      >
                          <Input.TextArea />
                      </Form.Item>

                      <Form.Item
                          label="Description"
                          name="description"
                      >
                          <Input.TextArea />
                      </Form.Item>

                      <Form.Item
                          wrapperCol={{
                          offset: 8,
                          span: 16,
                          }}
                      >
                          <Button type="primary" htmlType="submit">
                          Submit
                          </Button>
                      </Form.Item>
              </Form>
          </Drawer>
          <FeatureGroup>
            {mData?.connection ?
            <>
            <Polyline 
                  pathOptions={{ 
                    color: mData?._id?.color,
                      weight: '6',
                  }}
                  lineCap="Ok"
                  positions={mData?._id?.location}
                  eventHandlers={{
                  
                    click: () => {
                      handleView(mData?._id?.cable_id)
                    }
                  }}
                >
                
              </Polyline>
          {
            mData?.connection?.map((item,id) => (
              <Polyline 
                  key={id}
                  pathOptions={{ 
                    color: item?.color,
                      weight: '6',
                  }}
                  lineCap="Ok"
                  positions={item.location}
                  eventHandlers={{
                  
                    click: () => {
                      handleView(item?.cable_id)
                    }
                  }}
                >
                
              </Polyline>
                ))
          }
          </>
          : null
            }
            {data ?
                data?.map((item, id) => (
                  item?.core_available === true ? 
                    <Polyline
                      key={id}
                      dashArray= {
                        item?.type === 'underground' ? '2, 5' :
                        item?.type === 'wireless' ? '5, 10' 
                        : null}
                        
                      pathOptions={{ 
                        color: item?.core === 2 ? '#ADD8E6' :
                                item?.core === 4 ? '#e87472' :
                                item?.core === 8 ? 'grey' :
                                item?.core === 12 ? '#CBC3E3' :
                                item?.core === 24 ? '#FED8B1' :
                                item?.core === 36 ? '#FFFFE0' :
                                item?.core === 48 ? '#90EE90' :
                              'black',
                      weight: '6',
                    }}
                      lineCap="Ok"
                      positions={item?.path}
                      eventHandlers={{
                        click: () => {
                          handleView(item?.id)
                        },
                        mouseover: (e) => { e.target.openPopup()},
                        mouseout: (e) => { e.target.closePopup()}
                      
                      }}
                    >
                    <Popup>
                      <Row style={{ minWidth: '250px'}}>
                        <Col xs={10}><h3>Cable Id</h3></Col> <Col xs={14}><h3>: <strong>{item?.cable_id}</strong></h3></Col>
                        <Col xs={10}><h3>Core</h3></Col> <Col xs={14}><h3>: <strong>{item?.core}</strong></h3></Col>
                        <Col xs={10}><h3>Length</h3></Col> <Col xs={14}><h3>: <strong>{item?.length}</strong> meter</h3></Col>
                        <Col xs={10}><h3>Available Core</h3></Col> <Col xs={14}><h3>: <strong>{item?.core_available_count}</strong></h3></Col>
                      </Row>
                      {/* <h3>Cable Id: <strong>{item?.cable_id}</strong></h3>
                      <h3>Core: <strong>{item?.core}</strong></h3>
                      <h3>Length: <strong>{item?.length} meter</strong></h3> */}
                    </Popup>
    {/*                 
                        <Popup minWidth="300" >
                        <Row>
                            <Col xs={4}></Col>
                            <Col xs={16}>
                              <PopBox>
                                <h4>Cabel No- {item?.cable_id}</h4>
                                <h4>Core- {item?.core}</h4>
                                <h4>Description- {item?.description}</h4>
                                <Button onClick={() => handleView(item?.id)} size="small" icon={<EyeFilled />} type="primary" title='View'>View &nbsp;&nbsp;</Button>
                                <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => {handleDelete(item?.id)}}>
                                  <Button  size="small" icon={<DeleteOutlined />} type="primary"danger title='Delete'>Delete</Button>
                                </Popconfirm>
                                
                              </PopBox>
                            </Col>
                            <Col xs={4}></Col>
                        </Row>
                        
                        {core ? <CableDetails 
                                    refreshFnc={refreshFnc} refresh={refresh}
                                    setVisible={setVisible} visible={visible} 
                                    details={details} core={core}
                                    allPops={allPops} allClient={allClient} allJunction={allJunction} 
                                  /> 
                        : null}
                        
                        
                        </Popup> */}
                    
                    </Polyline>
                    :
                    
                    <Polyline
                    key={id}
                    dashArray= {
                      item?.type === 'underground' ? '2, 5' :
                      item?.type === 'wireless' ? '5, 10' 
                      : null}
                      pathOptions={{ 
                        color: item?.core === 2 ? 'blue' :
                                item?.core === 4 ? 'red' :
                                item?.core === 8 ? 'black' :
                                item?.core === 12 ? 'purple' :
                                item?.core === 24 ? 'orange' :
                                item?.core === 36 ? 'yellow' :
                                item?.core === 48 ? 'green' :
                              'black',
                          weight: '6',
                      }}
                    lineCap="Ok"
                    positions={item?.path}
                    eventHandlers={{
                      click: () => {
                        handleView(item?.id)
                      },
                      mouseover: (e) => { e.target.openPopup()},
                      mouseout: (e) => { e.target.closePopup()}
                    
                    }}
                  >
                    <Popup>
                      <Row style={{ minWidth: '250px'}}>
                        <Col xs={8}><h3>Cable Id</h3></Col> <Col xs={16}><h3>: <strong>{item?.cable_id}</strong></h3></Col>
                        <Col xs={8}><h3>Core</h3></Col> <Col xs={16}><h3>: <strong>{item?.core}</strong></h3></Col>
                        <Col xs={8}><h3>Length</h3></Col> <Col xs={16}><h3>: <strong>{item?.length}</strong> meter</h3></Col>
                      </Row>
                    </Popup>
                    </Polyline>
                ))
            :null}
            
            {core ? <CableDetails 
                                refreshFnc={refreshFnc} refresh={refresh}
                                setVisible={setVisible} visible={visible} 
                                details={details} core={core}
                                allPops={allPops} allClient={allClient} allJunction={allJunction} 
                                deleteFnc = {handleDelete}
                                bPoint={bPoint}
                                bPointFnc={bPointFnc}
                              /> 
                    : null}
          <EditControl
              position="topright"
              onCreated={_onCreated}
              onDrawVertex={() => isFocus(true)}
              onEdited={_onEdited}
              onDeleted={_onDeleted}
              onDrawStop = {() => markerFnc(true)}
              onDrawStart={() => {fnc2(null); markerFnc(false) }}
              edit={{
              remove: false,
              edit: false
              }}
              draw={{
                rectangle: false,
                circle: false,
                circlemarker: false,
                polygon: false,
                marker: false,
              }}
          />
          
          </FeatureGroup>
        </>
     );
}
 
export default connect(null,{ createCabel, cabelDetail, deleteCable, getPops, getJunction, getClients })(Cable);