import { Button, Col, Popconfirm, Row, Tabs } from 'antd';
import React, { useEffect, useState } from 'react'
import * as L from "leaflet";
import {
  Marker,
  Popup,
  useMap,
} from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { DeleteOutlined,EditOutlined, EyeFilled, RightOutlined } from '@ant-design/icons'
import { connect } from 'react-redux';
import { PopBox } from '../../styles/globalStyle';
import ClientDetails from './clientDetails'
import {getClientDetails, deleteClient} from '../../action/client'
import {getJunctionDetails} from '../../action/junction'
import {getPopDetails} from '../../action/pop'
import JunctionDetails from '../junction/junctionDetails';
import PopDetails from '../pop/popDetails';

const { TabPane } = Tabs;



const ClientMarker = ({ data, mapData,mData, 
  single, isFocus, isFocusFnc, getPopDetails, getJunctionDetails,
   getClientDetails, deleteClient, refreshFnc, refreshApi,
    fnc1, fnc2, fnc3 }) => {

  const [details, setDetails] = useState(null);
  const [popDetails, setpopDetails] = useState(null);
  const [junctiondetails, setJunctionDetails] = useState(null);
  const [visible, setVisible] = useState(false);
    

    const LeafIcon = L.Icon.extend({
      options: {
        iconSize:     [40, 40],
     }
    });
    const clienticon = (data) => new LeafIcon({
      iconUrl:  `./client.png`,
      iconSize: [50, 40],
      iconAnchor: [20, 16],
      popupAnchor: [0, 0],
    })
    const clienticonlg = (data) => new LeafIcon({
      iconUrl:  `https://cdn.iconscout.com/icon/free/png-256/location-marker-1719247-1460733.png`,
      iconSize: [30, 30],
      iconAnchor: [10, 25],
      popupAnchor: [0, 0],
    })
    const junctionicon = (data) => new LeafIcon({
      iconUrl:  `./Junction.png`,
      iconSize: [20, 20],
      iconAnchor: [12, 12],
      popupAnchor: [0, 0],
    })
    const junctioniconlg = (data) => new LeafIcon({
        iconUrl:  `https://cdn.iconscout.com/icon/free/png-256/location-marker-1719247-1460733.png`,
        iconSize: [40, 40],
        iconAnchor: [23, 35],
      popupAnchor: [0, 0],
    })
    const popicon = (data) => new LeafIcon({
      iconUrl:  `./POP.png`,
      iconSize: [20, 20],
      iconAnchor: [12, 12],
      popupAnchor: [0, 0],
    })

    const popiconlg = (data) => new LeafIcon({
      iconUrl:  `https://cdn.iconscout.com/icon/free/png-256/location-marker-1719247-1460733.png`,
      iconSize: [40, 40],
      iconAnchor: [23, 35],
      popupAnchor: [0, 0],
    })

  
  

      const handleDelete = async(id, pass)  => {
        const res = await deleteClient(id, pass)
        if(res === 201){
          refreshFnc(id)
          setVisible(false)
          setDetails(null)
          setJunctionDetails(null)
          setpopDetails(null)
        }
      }

      const handleView = async(name, id) => {
        if(name === 'pop'){
          const res = await getPopDetails(id)
          if(res){
            setpopDetails(res)
            setVisible(true)
            setJunctionDetails(null)
            setDetails(null)
            isFocusFnc(false)
          }
        }
        else if(name === 'junction'){
          const res = await getJunctionDetails(id)
          if(res){
            setJunctionDetails(res)
            setVisible(true)
            setDetails(null)
            setpopDetails(null)
            isFocusFnc(false)
          }
        }
        else{
          const res = await getClientDetails(id)
          if(res){
            setDetails(res)
            setVisible(true)
            isFocusFnc(false)
          }
        }
        
      }

      const refresh = async(id) => {
        const res = await getClientDetails(id)
        if(res){
          setDetails(res)
        }
      }
      
      const RecenterAutomatically = ({lat,lng}) => {
      const map = useMap();
      useEffect(() => {
        map.setView([lat, lng]);
      }, [lat, lng]);
      return null;
      }

      

    return ( 
        <>
        {/* ================ Focus Marker ============== */}
        {/* {mData?.connection ?
          mData?.connection?.map((item, i) => (
            <Marker key={i} position={item?.connection_point?.location} 
            icon={
              item?.connection_point?.entity === "junction" ? junctioniconlg() :
              item?.connection_point?.entity === "pop" ? popiconlg() :
               clienticonlg()
              }
               eventHandlers={{
                click: () => {
                  fnc1(item); fnc2(item?.position); fnc3("2");
                  handleView(item?.connection_point?.entity, item?.connection_point?.id)
                }
              }}
            >
                </Marker>
          ))
        :null} */}

            {data?.map((item,i) => (
                <>
                <Marker title={item?.client_id +'----'+ item?.name} key={i} position={item?.position} icon={clienticon()}
                 eventHandlers={{
                  click: () => {
                    fnc1(item); fnc2(item?.position); fnc3("2");
                    handleView("client", item?.id)
                  },
                  mouseover: (e) => { e.target.openPopup()},
                  mouseout: (e) => { e.target.closePopup()}}}
                  >
                    <Popup minWidth="300" >
                      <Row style={{ minWidth: '250px'}}>
                        <Col xs={8}><h3>Client Id</h3></Col> <Col xs={16}><h3>: <strong>{item?.client_id}</strong></h3></Col>
                        <Col xs={8}><h3>Name</h3></Col> <Col xs={16}><h3>: <strong>{item?.name}</strong></h3></Col>
                        <Col xs={8}><h3>Address</h3></Col> <Col xs={16}><h4>: <strong>{item?.address}</strong></h4></Col>
                      </Row>
                    </Popup>
                </Marker>
                
                </>
            ))}
                          
                                  
            {single?.position && single?.client_id 
            ? 
              isFocus ?
              <>
                  <Marker  title={single?.name} position={single?.position}  icon={clienticon()}
                  eventHandlers={{
                    click: () => {
                      fnc1(single); fnc3("1"); fnc2(single?.position);  
                      handleView('client',single?.id)
                    }
                  }}
                  > 
                  
                  {/* <RecenterAutomatically lat={single?.position[0]} lng={single?.position[1]} /> */}
                  </Marker>
              </>
            : 
              <>
              <Marker  title={single?.name} position={single?.position}  icon={clienticonlg()}
              eventHandlers={{
                click: () => {
                  fnc1(single); fnc3("1"); fnc2(single?.position);  
                  handleView("client",single?.id)
                }
              }}
              > 
              
              <RecenterAutomatically lat={single?.position[0]} lng={single?.position[1]} />
              </Marker>
              </>  
            : null}
            {junctiondetails ?
              <JunctionDetails 
              
              details={junctiondetails}
              visible={visible}
              closeFnc={() => setVisible(false)}
                // closeFnc={() => {setDetails(null); setVisible(false); fnc1(false)}}
              refresh={refresh}
              deleteFnc={handleDelete}
              mapData={mapData}
              />
              :
            null}
            {popDetails ?
              <PopDetails
              details={popDetails}
              visible={visible}
              closeFnc={() => setVisible(false)}
                // closeFnc={() => {setDetails(null); setVisible(false); fnc1(false)}}
              refresh={refresh}
              deleteFnc={handleDelete}
              mapData={mapData}
               />
              :
            null}
            {details ? 
                    <ClientDetails
                      details={details}
                      visible={visible}
                      refreshApi={refreshApi}
                      closeFnc={() => setVisible(false)}
                // closeFnc={() => {setDetails(null); setVisible(false); fnc1(false)}}
                      refresh={refresh}
                      deleteFnc={handleDelete}
                      mapData={mapData}
                    />
            :
            null}
        </>
     );
}
 
export default connect(null,{ getClientDetails, deleteClient, getPopDetails, getJunctionDetails })(ClientMarker);